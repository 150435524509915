body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 
  background-image: url('00.png'); /* Path to your image */
  background-size: cover;

}
body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color */
  z-index: 1; /* Position overlay behind content */
}

.announcement {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
  
  border-radius: 10px;
  position: relative;
  z-index: 2; /* Ensure text is above overlay */
}

.announcement p {
  font-size: 25px;
  font-style: italic;
}

.announcement h1 {
  font-size: 50px;
}
.announcement h2 {
  font-size: 35px;
}
.announcement a{
  text-decoration: none;
  color:white;
}
@media (max-width: 768px) {
  .announcement h1 {
    font-size: 30px;
  }
  .announcement h2 {
    font-size: 20px;
    
  }
}
